import { apiSlice } from "../apiSlice";

export const creaprusApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    setSavePermisionUser: builder.mutation({
      query: (parameters) => ({
        url: `/usuariosporareaserietipo`,
        method: "POST",
        body: parameters,
      }),
    }),
    getPermisionByUser: builder.query({
      query: (parameters) => ({
        url: `/usuariosporareaserietipo/${parameters.USRIDXXX}/${parameters.EMPNITXX}`,
        method: "GET",
        params: { ...parameters,
          per_page: 300 },
      }),
    }),
    setUpdatePermisionUser: builder.mutation({
      query: (parameters) => ({
        url: `/usuariosporareaserietipo/${parameters.USRIDXXX}`,
        method: "PUT",
        body: parameters,
      }),
    }),
    deletePermisionByUser: builder.mutation({
      query: (parameters) => ({
        url: `/usuariosporareaserietipo/${parameters.USRIDXXX}/${parameters.EMPNITXX}`,
        method: "PUT",
        body: parameters,
      }),invalidatesTags: ["reloadTable"],
    }),
    InactivatePermisionByUser: builder.mutation({
      query: (parameters) => ({
        url: `/inactivateuseareaserietipo/${parameters.TIPOOPEX}/${parameters.USRIDXXX}/${parameters.AREAIDXX}/${parameters.SERIEIDX}/${parameters.TIPOIDXX}/${parameters.EMPNITXX}`,
        method: "PUT",
        body: parameters,
      }),invalidatesTags: ["reloadTable"],
    }),
  }),
});

export const {
  useSetSavePermisionUserMutation,
  useLazyGetPermisionByUserQuery,
  useSetUpdatePermisionUserMutation,
  useDeletePermisionByUserMutation,
  useInactivatePermisionByUserMutation
 } = creaprusApiSlice;
