import { apiSlice } from "../apiSlice";

export const cremetxxApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getOptions: builder.query({
      query: () => ({
        url: `metadatosOpciones`,
        method: "GET",
      }),
    }),
    getMetadata: builder.query({
      query: ({ fields, filters }) => ({
        url:
          `metadatos?` +
          (fields !== "" ? fields : "") +
          (fields !== "" && filters !== "" ? "&" : "") +
          (filters !== "" ? filters : ""),
        method: "GET",
      }),
    }),
    createMetadata: builder.mutation({
      query: (parameters) => ({
        url: `metadatos`,
        method: "POST",
        body: parameters,
      }),
    }),
    updateMetadata: builder.mutation({
      query: (parameters) => ({
        url: `metadatos/${parameters.METIDXXX}`,
        method: "PUT",
        body: parameters,
      }),
      invalidatesTags: ["reloadTable"],
    }),
    updateRelationMetadata: builder.mutation({
      query: (parameters) => ({
        url: `updateMetadata`,
        method: "PUT",
        body: parameters,
      }),
      invalidatesTags: ["reloadTable"],
    }),
    getOptionsMetadata: builder.query({
      query: (parameters) => ({
        url: `opcionesMetadatos/${parameters.EMPNITXX}/${parameters.AREAIDXX}/${parameters.SERIEIDX}/${parameters.TIPOIDXX}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetOptionsQuery } = cremetxxApiSlice;
export const { useGetMetadataQuery } = cremetxxApiSlice;
export const { useLazyGetMetadataQuery } = cremetxxApiSlice;
export const { useLazyGetOptionsMetadataQuery } = cremetxxApiSlice;
export const { useCreateMetadataMutation } = cremetxxApiSlice;
export const { useUpdateMetadataMutation } = cremetxxApiSlice;
export const { useUpdateRelationMetadataMutation } = cremetxxApiSlice;
